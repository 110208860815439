<template>
  <v-container :style="`width: ${($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '' : '1200px !important'};`">
    <v-row justify="center">
      <v-col cols="12" md="3">
        <v-row class="mt-4">
          <v-col
            cols="12"
            class="d-flex align-center"
            style="border-bottom: 1px solid #e5e5e5;"
          >
            <div style="width: 100%;">
              <p
                class="mt-4 font-x-large"
                style="padding: 15px; border: 2px solid #e5e5e5; border-radius: 5px;"
              >
                {{ $t('product.category.list') }}
              </p>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="cursor-pointer d-flex align-center"
            style="border-bottom: 1px solid #e5e5e5;"
            v-for="(category, index) in categoriesList"
            :key="category + index"
            @click="onSelectCategory(category)"
          >
            <span class="text-justify font-weight-bold" style="color: #212121;">
              {{ $t(category.name) }}
            </span>
            <v-icon class="ml-2" v-if="category.selected">mdi-check</v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col
            cols="12"
            class="d-flex align-center"
            style="border-bottom: 1px solid #e5e5e5;"
          >
            <div style="width: 100%;">
              <v-checkbox
                v-model="isAvanceSearch"
                :label="$t('product.filter.advanceSearch')"
              />
            </div>
          </v-col>
          <v-col
            v-if="isAvanceSearch"
            cols="12"
          >
            <v-range-slider
              v-model="range"
              :max="maxRange"
              :min="'0'"
              :label="$t('productOrder.detail.price')"
              hide-details
              thumb-size="64px"
              class="align-center"
              append-icon="mdi-replay"
              @change="onMinMaxChanged"
              @click:append="onRemovePriceRange"
            >
              <template v-slot:thumb-label="item">
                {{ formatMoney(item.value) }}
              </template>
            </v-range-slider>
            <div class="d-flex justify-end">
              <span class="text-right font-small" style="align-self: center;">{{ formatMoney(range[0]) + ' VND' }}</span>
              <span class="mx-4 align-center justify-center" style="align-self: center;">-</span>
              <span class="font-small" style="align-self: center;">{{ formatMoney(range[1]) + ' VND' }}</span>
            </div>
          </v-col>
          <v-col
            v-if="isAvanceSearch"
            cols="12">
            <v-autocomplete
              :items="provinceList"
              v-model="provinceSelected"
              :label="$t('product.filter.province')"
              class="required"
              @change="onChangedProvince"
            >
              <template
                slot="selection"
                slot-scope="data">
                {{ $t(data.item.text) }}
              </template>
              <template
                slot="item"
                slot-scope="data">
                {{ $t(data.item.text) }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            v-if="isAvanceSearch"
            cols="12">
            <v-autocomplete
              :items="districtList"
              v-model="districtSelected"
              :label="$t('product.filter.district')"
              class="required"
              @change="onChangedDistrict"
            >
              <template
                slot="selection"
                slot-scope="data">
                {{ $t(data.item.text) }}
              </template>
              <template
                slot="item"
                slot-scope="data">
                {{ $t(data.item.text) }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            v-if="isAvanceSearch"
            cols="12">
            <v-autocomplete
              :items="wardList"
              v-model="wardSelected"
              :label="$t('product.filter.ward')"
              class="required"
              @change="onChangedWard"
            >
              <template
                slot="selection"
                slot-scope="data">
                {{ $t(data.item.text) }}
              </template>
              <template
                slot="item"
                slot-scope="data">
                {{ $t(data.item.text) }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            v-if="isAvanceSearch"
            cols="12">
            <div class="d-flex justify-center mb-3">
              <v-btn
                  color="primary"
                  @click="getProductList()"
                >
                  {{ $t('product.filter.search') }}
                </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <div style="clear: both;"></div>
      <v-col cols="12" md="9">
        <!-- Product list -->
        <v-row class="mt-8">
          <v-col cols="12" md="12" style="margin-bottom: 0; padding-bottom: 0;">
            <v-row class="d-flex justify-end">
              <v-col cols="12" md="6">
                <v-select
                  v-model="filterPriceTypeSelected"
                  :items="filterPriceTypeList"
                  @change="onFilterPriceTypeChange"
                  label="Solo field"
                  dense
                  solo
                >
                  <template slot="selection" slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    {{ $t(data.item.text) }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ $t(data.item.text) }}
                  </template>
                </v-select>
            </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="4" style="margin-top: 0; padding-top: 0;" v-for="(product, index) in productList" :key="product + index">
            <voucherCardComponent 
              :productData="product"
              :customCardClass="'mx-auto'"
              @emitProductDetailData="goProductDetail"
              @emitProductAddToCart="onAddToCart"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import functionUtils from 'Utils/functionUtils'
import StoreChannelType from 'Enum/storeChannelType'
import VoucherCardComponent from 'Components/VoucherCardComponent'
const RANGE_JUMP = 25000000
export default {
  components: {
    VoucherCardComponent
  },
  data() {
    return {
      chartOptions: {
        hoverBorderWidth: 20,
      },
      chartData: {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: [
          " Dxsale listing fee: 1%",
          "Burn: 10%",
          "Token for team: 4%",
          "Liquidity(1 year locked): 35%",
          "Presale: 50%",
        ],
        datasets: [
          {
            label: "Data One",
            backgroundColor: [
              "#41B883",
              "#E46651",
              "#00D8FF",
              "#A56653",
              "#552883",
            ],
            data: [1, 10, 4, 35, 50],
          },
        ],
      },
      cartList: [],
      productList: [],
      categoriesList: [],
      categoryId: null,
      ex3: { label: 'thumb-color', val: 50, color: 'red' },
      isAvanceSearch: false,
      maxRange: RANGE_JUMP,
      range: [0, 0],
      provinceSelected: null,
      provinceList: [],
      districtSelected: null,
      districtList: [],
      wardSelected: null,
      wardList: [],
      filterPriceTypeSelected: 1,
      filterPriceTypeList: [
        {
          text: 'product.filter.type.default',
          value: 1
        },
        {
          text: 'product.filter.type.highToLow',
          value: 2
        },
        {
          text: 'product.filter.type.lowToHigh',
          value: 3
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["VOUCHER_PRODUCT_LIST_DATA", "GET_VOUCHER_ORDER_CART_LIST_DATA"]),
  },
  watch: {
    GET_VOUCHER_ORDER_CART_LIST_DATA () {
      let data = this.GET_VOUCHER_ORDER_CART_LIST_DATA
      this.cartList = []
      data.forEach(e => {
        let item = {
          id: e.voucher_info.workpiece_id,
          image: e.voucher_info.image,
          quantity: e.quantity,
          price: e.voucher_info.price,
          name: e.voucher_info.name,
          code: e.voucher_info.serial,
          voucherOrderCartItemId: e.id,
          warehouse: e.warehouse
        }
        this.cartList.push(item)
      })
    },
    VOUCHER_PRODUCT_LIST_DATA() {
      let data = this.VOUCHER_PRODUCT_LIST_DATA;
      this.productList = [];
      for (let i = 0, size = data.length; i < size; i++) {
        let obj = {
          id: data[i].id,
          name: data[i].name,
          image: data[i].image_url,
          price: data[i].value,
          code: data[i].code,
          remainQuantity: data[i].warehouse,
          pricePromo: data[i].extra_info.product.price_promo,
          issuanceId: data[i].issuance_id,
          numberOfUsesVoucherValid: data[i].number_of_uses_voucher_valid
        };
        this.productList.push(obj);
      }
    },
    $route (){
      this.getProductList()
    }
  },
  created() {
    this.getProductList();
    this.getCategoriesList();
    this.getProvinceList();
    this.getVoucherOrderCart()
  },
  methods: {
    checkProductAddCart: function (cartList, productInfo, numberQuantityAdd) {
      return functionUtils.checkQuantityProductCanAddInCart(cartList, productInfo, numberQuantityAdd)
    },
    onMinMaxChanged: function (range) {
      if (range[1] >= this.maxRange) {
        this.maxRange += RANGE_JUMP
      } else if (range[1] + RANGE_JUMP < this.maxRange) {
        this.maxRange = Math.ceil(range[1] / RANGE_JUMP) * RANGE_JUMP
      }
    },
    /**
     * Filter price type change
     */
    onFilterPriceTypeChange: function () {
      this.getProductList();
    },
    /**
     * Remove price range
     */
    onRemovePriceRange: function () {
      this.range = [0, 0]
      this.getProductList();
    },
    /**
     * Format money
     */
    formatMoney: function (price) {
      return functionUtils.convertFormatNumberToMoney(price.toString())
    },
    /**
     * Go product detail
     */
    onAddToCart: function(product) {
      let checkProductAddCart = this.checkProductAddCart(this.cartList, product, 1)
      if (checkProductAddCart) {
        let productInfo = {
          workpieceId: product.id,
          quantity: 1,
          storeChannelId: StoreChannelType.PRODUCT_PORTAL
        }
        this.ADD_VOUCHER_ORDER_CART(productInfo).then(
          function () {
            this.getVoucherOrderCart()
            this.$toast.open({
              message: this.$t("cart.addToCartSuccessed"),
              type: "success",
              ...this.$toastConfig,
            });
          }.bind(this)
        )
      } else {
        this.$toast.open({
          message: this.$t("cart.addToCartOverQuantity"),
          type: "error",
          ...this.$toastConfig,
        });
      }
    },
    /**
     * Go product detail
     */
    goProductDetail: function(data) {
      this.$router.push({
        name: "ProductDetail",
        params: { id: data.productId },
        query: {
          issuanceId: data.issuanceId
        }
      });
    },
    /**
     * Get categories list
     */
    getCategoriesList: function() {
      this.GET_PRODUCT_CATEGORIES_LIST().then(
        function(res) {
          let data = res.data;
          this.categoriesList = [{ id: null, name: "product.category.all", selected: true }];
          for (let i = 0, size = data.length; i < size; i++) {
            data[i]["selected"] = false;
            this.categoriesList.push(data[i]);
          }
        }.bind(this)
      );
    },
    /**
     * Get product list
     */
    getProductList: function() {
      let search = this.$route.query.search
      let filter = {
        params: {
          categoryId: this.categoryId,
          search: search,
          rangePrice: this.range,
          provinceCode: this.provinceSelected,
          districtCode: this.districtSelected,
          wardCode: this.wardSelected,
          filterPriceType: this.filterPriceTypeSelected
        },
      };
      this.GET_PRODUCT_LIST(filter);
    },
    /**
     * Select category
     */
    onSelectCategory: function(category) {
      for (let i = 0, size = this.categoriesList.length; i < size; i++) {
        if (this.categoriesList[i].id === category.id) {
          category.selected = true;
          this.categoryId = category.id;
          this.getProductList();
        } else {
          this.categoriesList[i].selected = false;
        }
      }
    },
    getProvinceList: function () {
      this.GET_LIST_PROVINCE({ id: 84 }).then(
        function (res) {
          var isFound = false
          let data = res.data
          this.provinceList = [{ text: '---', value: null }]
          for (let i = 0, size = data.length; i < size; i++) {
            let provinceObj = {
              value: data[i].code,
              text: data[i].type + ' ' + data[i].name
            }
            this.provinceList.push(provinceObj)
            if (data[i].code === this.provinceSelected) {
              isFound = true
            }
          }
          if (!isFound) {
            this.provinceSelected = 0
          }
          this.getDistrictList()
        }.bind(this)
      )
    },
    getDistrictList: function () {
      if (this.provinceSelected > 0) {
        this.GET_LIST_DISTRICT({ id: this.provinceSelected }).then(
          function (res) {
            var isFound = false
            let data = res.data
            this.districtList = [{ text: '---', value: null }]
            for (let i = 0, size = data.length; i < size; i++) {
              let districtObj = {
                value: data[i].code,
                text: data[i].type + ' ' + data[i].name
              }
              this.districtList.push(districtObj)
              if (data[i].code === this.districtSelected) {
                isFound = true
              }
            }
            if (!isFound) {
              this.districtSelected = 0
            }
            this.getWardList()
          }.bind(this)
        )
      } else {
        this.districtList = [{ text: '---', value: null }]
        this.districtSelected = 0
        this.getWardList()
      }
    },
    getWardList: function () {
      if (this.districtSelected > 0) {
        this.GET_LIST_WARD({ id: this.districtSelected }).then(
          function (res) {
            var isFound = false
            let data = res.data
            this.wardList = [{ text: '---', value: null }]
            for (let i = 0, size = data.length; i < size; i++) {
              let wardObj = {
                value: data[i].code,
                text: data[i].type + ' ' + data[i].name
              }
              this.wardList.push(wardObj)
              if (data[i].code === this.wardSelected) {
                isFound = true
              }
            }
            if (!isFound) {
              this.wardSelected = 0
            }
          }.bind(this)
        )
      } else {
        this.wardList = [{ text: '---', value: null }]
        this.wardSelected = 0
      }
    },
    onChangedProvince: function () {
      if (this.provinceSelected == null) {
        this.provinceSelected = 0
      }
      this.districtSelected = 0
      this.districtList = [{ text: '---', value: null }]
      this.wardSelected = 0
      this.wardList = [{ text: '---', value: null }]
      this.getDistrictList()
    },
    onChangedDistrict: function () {
      if (this.districtSelected == null) {
        this.districtSelected = 0
      }
      this.wardSelected = 0
      this.wardList = [{ text: '---', value: null }]
      this.getWardList()
    },
    onChangedWard: function () {
      if (this.wardSelected == null) {
        this.wardSelected = 0
      }
    },
    getVoucherOrderCart: function () {
      let filter = {
        params: {
          storeChannelId: StoreChannelType.PRODUCT_PORTAL
        }
      }
      this.GET_VOUCHER_ORDER_CART_LIST(filter).then(
        function () {}.bind()
      )
    },
    ...mapActions(["GET_PRODUCT_LIST", "GET_PRODUCT_CATEGORIES_LIST", "ADD_VOUCHER_ORDER_CART", 'GET_LIST_PROVINCE', 'GET_LIST_DISTRICT', 'GET_LIST_WARD', 'GET_VOUCHER_ORDER_CART_LIST']),
  },
};
</script>
<style scoped>
#about {
  background-color: #f4f7f5;
}
.cursor-pointer {
  cursor: pointer;
}
figure.sixteen-nine-img {
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding-top: 56.25%;
  position: relative;
}
figure.sixteen-nine-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
</style>
